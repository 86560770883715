<template>
  <div>
    <section>
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-7 text-center mb-5">
            <h1>LIFESTYLE</h1>
            <p>Immerse yourself in the art of indulgence, where luxury living takes centre stage.<br> Discover secluded
              holiday destinations, exquisite wines, the finest restaurants, private events, flawless interior decorating,
              inspiring landscaping designs and more. Embrace the extraordinary and delve into a lavish lifestyle.</p>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/ubuntu.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h2 class="text-start text-uppercase"><strong>The Search for Connection: How Ubuntu Informs Luxury Living in SA</strong></h2>
                  <p>In the heart of South Africa’s architectural evolution, a deep-seated philosophy is showing up in an increasing number of luxury homes and lifestyle estates.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/search-for-connection"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/elevating-luxury-living.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h2 class="text-start text-uppercase"><strong>Elevating Luxury Living: The Art of Creating After-Dark Retreats</strong></h2>
                  <p>As remote work continues to redefine modern lifestyles, homeowners are investing more than ever in transforming their residences into versatile sanctuaries. With more time spent at home, living spaces are evolving into multi-functional areas that cater to work, leisure and entertainment.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/elevating-luxury-living"
              id="lifestyle-cta">READ MORE</a>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>